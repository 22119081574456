import React from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import styled from 'styled-components';

export default function DownloadBoardmakerCard() {
  const { t } = useTranslation();
  return (
      <ButtonContainer>
        <button
            id="downloadBoardmaker"
            data-testid="downloadBoardmaker"
            className="btn btn-primary"
            onClick={() => {
              navigate('/downloads')
            }}
          >
            {t('download.download')}
        </button>
      </ButtonContainer>
  );
}

const ButtonContainer = styled.div`
  padding-top: 10px;
`;