import React from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useUser } from '@util/providers/AuthProvider';
import { usePlan } from '@util/providers/Plan';

const CommunitySignUp = ({ includeBackButton = true }) => {
  const { t } = useTranslation();
  const { ssoUser } = useUser();
  const { plans, setSelectedPlan, setSelectedRate, termIsYearly } = usePlan();
  const plan = plans.find(plan => plan.key === 'community');

  setSelectedPlan(plan);
  setSelectedRate(getRatePlanByType(plan, false));

  function getRatePlanByType(p, isTrial) {
    if (p.staticRatePlan) return p.staticRatePlan;
    if (isTrial) return p.ratePlans.find(rp => rp.isTrial);
    return p.ratePlans.find(rp => (termIsYearly ? rp.isYearly : rp.isMonthly));
  }

  return (
    <>
      <div className="row pl-3 py-3">
        <h2 className="pb-3">{t('download.communityStart.header')}</h2>
        <div className="row pl-3">
          <p className="pb-2">{t('download.communityStart.body')}</p>
          <ul className="w-100">
            <li>{t('download.communityStart.pointOne')}</li>
            <li>{t('download.communityStart.pointTwo')}</li>
            <li>{t('download.communityStart.pointThree')}</li>
          </ul>
          <p className="pt-1 w-100">{t('download.communityStart.footer')}</p>
          <div>
            <button
              className="btn btn-primary"
              id="CommunitySignUp"
              onClick={() => {
                navigate(ssoUser ? '/community/start' : '/account', {
                  state: { hideSubscriptionPlans: true },
                });
              }}
            >
              {t('action.signUp')}
            </button>
            {includeBackButton && (
              <button
                onClick={() => {
                  window ? window.history.back() : navigate('/');
                }}
                className="btn btn-outline-secondary ml-2"
              >
                {t('action.back')}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommunitySignUp;
