import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PageHeader from '@ui/PageHeader';
import SEO from '@components/seo';

import { learnMoreDetails } from '@data';
import CommunitySignUp from '@components/Miscellaneous/CommunitySignUp';
import DownloadBoardmakerCard from '@components/Downloads/DownloadBoardmakerCard';
import { SystemRequirementsModal } from '@components/Details/SystemRequirements/SystemRequirements';

const trialLength = 30;

export default function TrialStart() {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const pageTitle = t('page:perpetualTrial', { trialLength });

  return (
    <>
      <SEO title={pageTitle} />
      <PageHeader className="mb-4">{pageTitle}</PageHeader>
      <p>
        {/* prettier-ignore */}
        <Trans i18nKey="perpetual.trial.intro">
            No credit card. No commitment. Just {{trialLength}} consecutive days
            of unrestricted access to our latest Boardmaker software. After
            downloading and installing the software onto your computer, you can
            request a {{trialLength}}-day activation key<sup>*</sup> from within the
            software.
        </Trans>
      </p>
      <small className="text-muted">
        {/* prettier-ignore */}
        <Trans i18nKey="perpetual.trial.footnote">
          <sup>*</sup>Trials start the day our software is activated and will
          remain active for {{trialLength}} consecutive days.
        </Trans>
      </small>
      <div>
        <DownloadBoardmakerCard />
      </div>
      <CommunitySignUp />
      <SystemRequirementsModal
        planDetails={learnMoreDetails.boardmakerLicense}
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
      />
    </>
  );
}
