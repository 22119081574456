import React from 'react';
import URLS from '@config/urls';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function DetailsSystemRequirements({
  planDetails,
  className,
  inModal,
}) {
  const { t } = useTranslation();
  return (
    <div className={classNames('pt-2', className)}>
      {!inModal && (
        <>
          <h3 className="d-none d-sm-block border-top py-4 mb-0">
            {t('systemRequirements')}
          </h3>
          <h3 className="d-block d-sm-none pl-3 py-3 border-top mb-0">
            {t('systemRequirements')}
          </h3>
        </>
      )}

      <div className="border-bottom">
      {!inModal && ( 
      <>
        <Trans i18nKey="download.viewSystemRequirements">
          View the 
          <a 
            href={URLS.BOARDMAKER_REQUIREMENTS} 
            rel="noopener noreferrer" 
            target="_blank"
          >
            {{ guideLink: 'Boardmaker 7 Compatibility Guide' }}
          </a> 
          for system requirements.
        </Trans>
      </>
      )}
      {inModal && (
        planDetails.requirements.operatingSystem?.length > 0 && (
          <SystemRequirementsSection
            headers={[
              t('operatingSystem.title'),
              t('minimum'),
              t('recommended'),
            ]}
            entries={planDetails.requirements.operatingSystem}
          />
        )
      )}
      {inModal && (
        planDetails.requirements.browser?.length > 0 && (
          <SystemRequirementsSection
            headers={[t('browser')]}
            entries={planDetails.requirements.browser}
          />
        )
      )}
      {inModal && (
        planDetails.requirements.eyeTracker?.length > 0 && (
          <>
            <h2 className="text-center d-none d-sm-block border-top py-4 mb-0">
              {t('supportedPeripherals')}
            </h2>
            <h2 className="text-center d-block d-sm-none pl-3 py-3 border-top border-bottom mb-0">
              {t('supportedPeripherals')}
            </h2>
            <SystemRequirementsList
              headers={[t('tobiiDynavoxEyeTrackers')]}
              entries={planDetails.requirements.eyeTracker}
            />
          </>
        )
      )}
      </div>
    </div>
  );
}

function SystemRequirementsSection({ headers, entries }) {
  return (
    <div className="container">
      <div className="row font-weight-bold py-3 border-top">
        {headers.map((item, key) => (
          <div className="col-4" key={key}>
            {item}
          </div>
        ))}
      </div>

      {entries.map((item, key) => (
        <div key={key} className="row">
          {item.map((item2, key2) => (
            <div key={key2} className="col-4 border-top py-3">
              {item2}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

function SystemRequirementsList({ headers, entries }) {
  return (
    <div className="container">
      <div className="row font-weight-bold py-3 border-top">
        {headers.map((item, key) => (
          <div className="col-12" key={key}>
            {item}
          </div>
        ))}
      </div>
      <div className="row p-3 border-top">
        {entries.map((item, key) => (
          <span key={key}>{item}&nbsp;</span>
        ))}
      </div>
    </div>
  );
}

export function SystemRequirementsModal({
  planDetails,
  isOpen,
  toggle,
  className,
}) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle} className={className}>
      <ModalHeader toggle={toggle} charCode="">
        {t('systemRequirements')}
      </ModalHeader>
      <ModalBody>
        <DetailsSystemRequirements planDetails={planDetails} inModal />
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
}
